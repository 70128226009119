export type Location = any;
export type Technician = any;

export enum UserGroup {
  'Tour Planer' = 'planer',
  'Hotel Planer' = 'hotel',
  'Vertrieb' = 'sales',
  'Administrator' = 'admin',
}

export function UserGroupByString(group: string): UserGroup | undefined {
  if (group === 'Administrator') return UserGroup.Administrator;
  if (group === 'Tour Planer') return UserGroup['Tour Planer'];
  if (group === 'Hotel Planer') return UserGroup['Hotel Planer'];
  if (group === 'Vertrieb') return UserGroup.Vertrieb;
}

export interface User {
  name: string;
  initialsOverwrite?: string;
  readonly defautlInitials: string;
  email: string;
  username: string;
  group: UserGroup;
}

export enum JOB_TYPE {
  Home = 0,
  Location = 1,
  HotelAccommodation = 2,
  Technician = 3,
  HomeAccommodation = 4,
}

export interface RouteInfo {
  distance: number;
  duration: number;
  legs?: any[];
}

export interface JobCommon {
  _id?: string;
  tour?: string;
  sort: number;
  name: string;
  nearByAddress?: string;
  planedAt?: Date | string;
  planedAtTimeByUser?: boolean;
  until?: string;
  hasFixedDate?: boolean;
  fixed: boolean;
  comment?: string;
  note?: string;
  fixedDateFromAdditionalTasks?: string;
}

export interface Leg {
  distance: number;
  duration: number;
}

export type JobCommonWithLeg = JobCommon & {
  leg: Leg;
};

export type HotelJobBase = JobCommon & {
  type: JOB_TYPE.HotelAccommodation;
  isHotel: true;
  isHome: false;
  hotelName?: string;
  address?: any;
};

export type HomeAccommodationJobBase = JobCommon & {
  type: JOB_TYPE.HomeAccommodation;
  isHotel: false;
  isHome: true;
  address: any;
};

export interface JobBase {
  type: JOB_TYPE.Home | JOB_TYPE.HomeAccommodation | JOB_TYPE.Location;
  isHotel: false;
  isHome: false;
  // common
  address: any;
  splited: boolean;
  ref: string;
  planedAtTimeByUser?: boolean;
  until?: string;
  hasFixedDate?: boolean;
  refIndex: number;
  comment?: string;
  note?: string;
  operatingExpense: number;
  duePrice: number;
  // location
  defaultTasks: { [key: string]: boolean };
  operatingExpenses: { [key: string]: number };
  interval: { [key: string]: number };
  checkDepth: { [key: string]: number };
  price: { [key: string]: number };
  lastJob: { [key: string]: any };
  dues: { [key: string]: string };
  tag: string;
  totalContractValue: 0;
  priceCombiCondition: { [key: string]: any };
  dueFixedDate: { [key: string]: any };
  _dues: { [key: string]: string | boolean };
  holidays: any[];
  //
  planHours: number; // required: true
  actualWorkingHours?: number;
  actualRuWHours?: number;
  estimatedPrice?: number;
  doneAt?: Date | string;
  failed?: boolean;
  completed?: boolean;
  // tasks
  tasks: { [key: string]: boolean };
  //
  rejected?: boolean;
  confirmed?: boolean;
}

export type NewJob = {
  sort: number;
  type:
    | JOB_TYPE.HotelAccommodation
    | JOB_TYPE.HomeAccommodation
    | JOB_TYPE.Location;
  address?: any;
  nearByAddress?: string;
  planedAt: Date | string;
  planedAtTimeByUser?: boolean;
  until?: string;
  hasFixedDate?: boolean;
  // operatingExpense?: number;
  // duePrice?: number;
  location?: Location;
  // comment?: string;
  // checkDepth?: number;
  // tasks?: { [key: string]: boolean };
};

export type Job = JobCommon &
  JobBase & { location?: Location; tasks?: Record<string, boolean> };
export type JobWithLeg = JobCommonWithLeg & JobBase;

export type HotelJob = JobCommon & HotelJobBase;
export type HotelJobWithLeg = JobCommonWithLeg & HotelJobBase;

export type HomeAccommodationJob = JobCommon & HomeAccommodationJobBase;
export type HomeAccommodationJobWithLeg = JobCommonWithLeg &
  HomeAccommodationJobBase;

export type Route = {
  distance: number;
  duration: number;
  technician: Technician;
  jobs: (Job | HotelJob | HomeAccommodationJob)[];
};

export type Tour = {
  _id: string;
  sharedAt: {
    rel?: Date;
    preview?: Date;
  };
  jobs: (Job | HotelJob | HomeAccommodationJob)[];
  enshrined: boolean;
  technician: Technician;
  startDate?: string;
  endDate?: string;
  changedAt?: Date;
  createdAt?: Date;
  changedBy?: any;
  createdBy?: any;
  creadtedAt?: Date;
  planDrivingHours: number;
  planWorkingHours: number;
  planDrivingDistance: number;
  estimatedPrice: number;
  tag: number;
  rentaPlan: number;
  mehrPlanKM?: number;
  tagessatzPlan: number;
  actualDrivingDistance?: number;
  actualDrivingHours?: number;
  actualPrice?: number;
  actualRuWHours?: number;
  actualWorkingHours?: number;
  mehrIstKM?: number;
  rentaIst?: number;
  tagessatzIst?: number;
  containsRejected?: boolean;
  permissionGranted?: {
    at: Date;
    by: string;
  };
};
