import { notification } from 'antd';
import { Formik, FormikErrors, FormikHelpers, FormikValues } from 'formik';
import { forEach, get, reduce, set } from 'lodash';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';
import { Request } from '../../../api/Request';
import { AuthConsumer } from '../../../context/AuthContext';
import { Job, Tour } from '../../../context/Route';
import { hasPermissionFunc } from '../../../context/hasPermissionFunc';
import InPageNavigation from '../../InPageNavigation/InPageNavigation';
import {
  PageHeaderAction,
  PageHeaderActions,
} from '../../PageHeader/PageHeaderActions';
import renderLoading from '../../SharesActions/renderLoading';
import ComparisonField from './ComparisonField';
import TourJob from './TourJob';
import WatchWorkingHours from './WatchWorkingHours';
import Schema, { STATUS } from './Schema';
import qs from 'query-string';
import styled from 'styled-components';

interface ToursResponseViewProps {}

const SickBanner = styled.div`
  background-color: rgb(255, 195, 77);
  color: white;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 1em;
  font-weight: bold;
`;

export type IFormikValues = {
  timestamp: number;
  actualPrice: number;
  actualDrivingDistance: number;
  actualWorkingHours: number;
  actualDrivingHours: number;
  actualRuWHours: number;
  planDrivingDistance: number;
  planWorkingHours: number;
  planDrivingHours: number;
  jobs: {
    [jobID: string]: {
      status: string;
      other: string;
      reason: string;
      planHours: number;
      actualWorkingHours?: number;
      tasks: {
        [taskID: string]: {
          transferWorkingHours?: number;
          status: string;
          other: string;
          reason: string;
          planHours: number;
          actualWorkingHours?: number;
        };
      };
    };
  };
};

const ToursResponseView: FunctionComponent<ToursResponseViewProps> = ({}) => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const location = useLocation();
  const queryParams = qs.parse(location.search);
  const sick =
    get(queryParams, 'sick', '')?.toString() === 'true' ||
    get(queryParams, 'sick', '')?.toString() === '1';

  //
  const [loading, setLoading] = useState<boolean>(true);
  const [tour, setTour] = useState<Tour>();

  //
  const [notCollapsed, setNotCollapsed] = useState<string | undefined>();

  // Load tour
  useEffect(() => {
    // Load tour
    const loadTour = async () => {
      try {
        setLoading(true);
        const tour: Tour | undefined = await Request.get('/tours', id, 'rsp');

        // if (sick && tour) {
        //   forEach(tour.jobs, (job, i) => {
        //     forEach((job as any).tasks ?? [], (task, k) => {
        //       if (task && k !== '_id') {
        //         console.log(`jobs.${i}.status`);
        //         console.log(`jobs.${i}.tasks.${k}.status`);

        //         set(tour, `jobs.${i}.status`, 'technician-sick');
        //         set(tour, `jobs.${i}.tasks.${k}.status`, 'technician-sick');
        //       }
        //     });
        //   });
        // }

        // console.log('actualWorkingHours', tour.actualWorkingHours);
        setTour(tour);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    loadTour();
  }, [id]);

  if (loading) return renderLoading();

  if (!tour) return <Redirect to={`/tours/${id}/`} />;

  const tourJobs = tour.jobs.filter((j) => !j.isHome && !j.isHotel) as Job[];

  const getSOR = (job: Job, prefix: string) => {
    const reason = get(job, `${prefix}reason`, '');

    let status = get(job, `${prefix}state`, 'planed');

    if (sick) {
      status = 'technician-sick';
    }

    if (STATUS.includes(status)) {
      return {
        status,
        other: '',
        reason,
      };
    } else {
      return {
        status: 'other',
        other: status,
        reason,
      };
    }
  };

  return (
    <Formik<IFormikValues>
      validateOnMount
      validateOnChange={false}
      validateOnBlur={true}
      initialValues={{
        timestamp: 0,
        actualPrice: tour.estimatedPrice,

        actualDrivingDistance: tour.actualDrivingDistance ?? 0,
        actualWorkingHours: tour.actualWorkingHours ?? 0,
        actualDrivingHours: tour.actualDrivingHours ?? 0,
        actualRuWHours: tour.actualRuWHours ?? 0,
        planDrivingDistance: tour.planDrivingDistance,
        planWorkingHours: tour.planWorkingHours,
        planDrivingHours: tour.planDrivingHours,

        jobs: reduce(
          tourJobs,
          (acc, job) => {
            acc[job._id!] = {
              ...getSOR(job, ''),
              planHours: job.planHours,
              actualWorkingHours: job.actualWorkingHours,
              tasks: reduce(
                job.tasks,
                (acc, v, task) => {
                  if (v && task !== '_id') {
                    acc[task] = {
                      transferWorkingHours: undefined,
                      ...getSOR(job, `taskStates.${task}.`),
                      actualWorkingHours: get(
                        job,
                        `taskActualWorkingHours.${task}`
                      ),
                      planHours: get(
                        job,
                        `location.operatingExpenses.${task}`,
                        0
                      ),
                    };
                  }
                  return acc;
                },
                {}
              ),
            };
            return acc;
          },
          {}
        ),
      }}
      validationSchema={Schema}
      onSubmit={async function (values, formikHelpers): Promise<void> {
        try {
          await Request.post(`tours/${id}/rsp`, values);
          history.push(`/tours/${id}`);
        } catch (error: any) {
          const msg = get(error, 'response.body.message');
          notification.error({
            message: 'Fehler',
            description: msg || error.message || JSON.stringify(error),
            duration: 10000,
          });
        } finally {
          formikHelpers.setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, values, isValid, submitForm }) => (
        <>
          {isSubmitting && renderLoading()}
          {!isSubmitting && (
            <AuthConsumer>
              {({ hasPermission }: { hasPermission: hasPermissionFunc }) => {
                if (hasPermission(['tour:write']) !== true) {
                  return <Redirect to={`/tours/${tour ? tour._id : ''}/`} />;
                }

                return (
                  <>
                    <div className='tours container-inner'>
                      <InPageNavigation
                        to={`/tours/${tour ? tour._id : ''}/`}
                        tour={tour}
                      />
                      <div className='page-header page-header-line row justify-content-between'>
                        <div className='col col-12 col-md-6'>
                          <h1 className='page-title'>
                            {tour ? (
                              <span data-testid='title'>
                                {`REL-Erfassung ${tour.tag} | ${tour.technician.name}`}
                              </span>
                            ) : (
                              <span />
                            )}
                          </h1>
                        </div>
                        <PageHeaderActions className='col col-12 col-md-6 page-header-actions justify-content-md-end pt-md-0'>
                          <PageHeaderAction
                            disabled={!isValid}
                            onClick={() => submitForm()}
                          >
                            <p>{'Speichern'}</p>
                          </PageHeaderAction>
                        </PageHeaderActions>
                      </div>
                      {sick ? (
                        <SickBanner>
                          Alle Standorte wurden auf &quot;Techniker krank&quot;
                          eingestellt!
                        </SickBanner>
                      ) : null}
                      <div className={`page-content`}>
                        <div className='row'>
                          <div className='col col-12 col-xs-12 col-lg-6 col-xl-4'>
                            <ComparisonField
                              tour={tour}
                              title='Umsatz'
                              name='actualPrice'
                              compare='estimatedPrice'
                              suffix='€'
                              dangerForLower={true}
                            />
                          </div>
                          <div className='col col-12 col-xs-12 col-lg-6 col-xl-4'>
                            <ComparisonField
                              tour={tour}
                              title='Fahrstrecke'
                              name='actualDrivingDistance'
                              compare='planDrivingDistance'
                              suffix='km'
                              dangerForHigher={true}
                            />
                          </div>
                          <div className='col col-12 col-xs-12 col-lg-6 col-xl-4'>
                            <ComparisonField
                              tour={tour}
                              title='Prüfzeit'
                              name='actualWorkingHours'
                              compare='planWorkingHours'
                              suffix='h'
                              readOnly={true}
                              dangerForHigher={true}
                            />
                          </div>
                          <div className='col col-0 col-xs-0 col-lg-6 col-xl-4'></div>
                          <div className='col col-12 col-xs-12 col-lg-6 col-xl-4'>
                            <ComparisonField
                              tour={tour}
                              title='Fahrzeit'
                              name='actualDrivingHours'
                              compare='planDrivingHours'
                              suffix='h'
                              readOnly={false}
                              dangerForHigher={true}
                            />
                          </div>
                          <div className='col col-12 col-xs-12 col-lg-6 col-xl-4'>
                            <ComparisonField
                              tour={tour}
                              title='R.u.W.-Zeit'
                              name='actualRuWHours'
                              suffix='h'
                            />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col col-12'>
                            <div className='row table-divider'>
                              <div className='col'>
                                <span>Einsätze</span>
                              </div>
                            </div>
                            <div className='row table-row'>
                              <div className='col'>
                                {tourJobs.map((job) => (
                                  <TourJob
                                    key={job._id}
                                    tour={tour}
                                    job={job as Job}
                                    collapsed={notCollapsed !== job._id}
                                    onCollapse={(job, v) => {
                                      if (v) {
                                        setNotCollapsed(undefined);
                                      } else {
                                        setNotCollapsed(job._id);
                                      }
                                    }}
                                  />
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <WatchWorkingHours />
                  </>
                );
              }}
            </AuthConsumer>
          )}
        </>
      )}
    </Formik>
  );
};

export default ToursResponseView;
