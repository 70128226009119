import { Col, Popconfirm, Row } from 'antd';
import { useField } from 'formik';
import { chain, reduce } from 'lodash';
import { DateTime } from 'luxon';
import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as ClockIcon } from '../../assets/icons/calender.svg';
import { ReactComponent as ToursIcon } from '../../assets/icons/tours.svg';
import { ReactComponent as TechIcon } from '../../assets/icons/technician2.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';
import { ReactComponent as StatusIcon } from '../../assets/icons/status.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';

import { CollapseButton } from '../common/CollapseButton';
import { JobsAtLocation } from './LocationJobs2';
import renderTask from './renderTask';
import classNames from 'classnames';

const Wrapper = styled.div`
  margin: 0 0 24px 0;
`;

const TaskWrapper = styled.div`
  background-color: #f6f6f6;
  border-radius: 3px;
  display: flex;
  min-height: 68px;
  justify-content: space-between;
  margin: 6px;
  padding: 20px 14px 20px 62px;
  position: relative;
  z-index: 0;
  .ant-row {
    width: 100%;
  }
`;

export const JobDetails = styled.div<{
  color: string;
}>`
  background-color: #fff;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  margin: 12px 0;
  padding: 20px 56px 20px 20px;
  position: relative;
  width: 100%;
  z-index: 0;
  align-items: center;
  .ant-row {
    width: 100%;
  }
  color: ${({ color }) => color};
  svg {
    fill: ${({ color }) => color};
    p,
    path,
    g {
      fill: ${({ color }) => color};
    }
  }
`;

export const LocationName = styled.p`
  color: #000;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 14px;
  overflow: hidden;
  padding: 0 0 1px;
  white-space: nowrap;
  width: 360px;
  min-width: 360px;
  max-width: 360px;
`;

export const Address = styled.p`
  color: #5e5e5e;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 12px;
  padding: 1px 0 0;
  overflow: hidden;
`;

export const Date = styled.p`
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 12px;
  padding: 1px 0 0;
  overflow: hidden;
  line-height: 28px;
  width: 100%;
  // width: 110px;
  // min-width: 110px;
  // max-width: 110px;
  svg {
    margin: 5px;
    height: 18px;
    width: 18px;
    position: relative;
    top: -2px;
  }
`;

const Tasks = styled.div``;

const ErrorDisplay = styled.div<{ show: boolean }>`
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: start;
  // min-height: 1rem;
  // max-height: 1rem;
  // height: 1rem;
  // opacity: ${({ show }) => (show ? '1' : '0')};
  // overflow: hidden;
  // font-size: 0.8rem;
  // color: red;
  // transition: opacity 0.2s ease-in-out;
  // margin: 0;
  // padding: 0.2rem;
  // width: 100%;
  background-color: #fff;
  color: #ff0000;
  font-weight: 400;
  border-radius: 3px;
  justify-content: space-between;
  margin: -12px 0 12px 0;
  padding: 20px 56px 20px 120px;
  position: relative;
  width: 100%;
  z-index: 0;
  align-items: center;
  display: ${({ show }) => (show ? 'flex' : 'none')};
`;

interface LocationJobProps {
  job: JobsAtLocation;
  filterSystem: boolean;
  tasks: any;
  onDeleteJob: (job: string) => void;
}

function renderDate(job?: JobsAtLocation) {
  if (!job) return '';
  if (!job.planedAt) return '';
  return DateTime.fromISO(job.planedAt).toFormat(
    "yyyy '|' 'KW' WW '|' dd.MMM."
  );
}

function renderTour(job?: JobsAtLocation) {
  if (!job) return '';
  if (!job.tour) return '';
  return job.tour.tag;
}

function renderTech(job?: JobsAtLocation) {
  if (!job) return '';
  if (!job.tour?.technician) return '';
  return job.tour.technician!.name;
}

function renderPlaner(job?: JobsAtLocation) {
  if (!job) return '';
  if (!job.tour?.changedBy?.name) return '';
  return job.tour.changedBy.name;
}

function renderStatus(job?: JobsAtLocation) {
  if (!job) return '';
  if (job.state === 'not-completed-technician-sick')
    return 'Teilabgeschlossen - Techniker krank';
  if (job.state === 'not-made-in-time') return 'Teilabgeschlossen - zeitlich';
  if (job.state === 'other') return 'Teilabgeschlossen - anderer Grund';
  if (job.state === 'completed') return 'Abgeschlossen';
  if (job.state === 'not-completed') return 'Nicht abgeschlossen';
  if (job.state === 'not-possible') return 'Nicht möglich';
  if (job.state === 'technician-sick') return 'Techniker krank';
  if (job.state === 'mixed') return 'Pro Prüfart';
  if (job.state === 'planed') return 'Geplant';
  return job.state;
}

function renderReason(job?: JobsAtLocation) {
  if (!job) return '';
  if (job.reason) return job.reason;
  return '';
}

function colorForStatus(job?: JobsAtLocation) {
  if (job && job.state === 'planed') return '#009842';

  if (job && job.state === 'completed') return 'rgb(161, 161, 161)';
  if (job && job.state === 'not-completed') return 'rgb(161, 161, 161)';

  if (job && job.state === 'not-possible') return '#C09449';
  if (job && job.state === 'technician-sick') return '#C09449';

  if (job && job.state === 'not-completed-technician-sick') return '#86cba4';
  if (job && job.state === 'not-made-in-time') return '#86cba4';

  return '#000';
}

const Trash = styled(TrashIcon)`
  height: 12px;
  color: #000;
  cursor: pointer;
  path {
    fill: rgb(161, 161, 161) !important;
    &:hover {
      fill: #ff0000 !important;
    }
  }
`;

function renderRemove(job: JobsAtLocation, onDelete: (job: string) => void) {
  if (job && job.tour && job.tour.tag) {
    return (
      <span>
        <Popconfirm
          title='Sind Sie sich sicher?'
          onConfirm={() => {
            onDelete(job._id);
          }}
          okText='Ja'
          cancelText='Nein'
        >
          <span
            style={{
              lineHeight: '20px',
              cursor: 'pointer',
            }}
          >
            <Trash />
          </span>
        </Popconfirm>
      </span>
    );
  } else {
    return <span></span>;
  }
}

// value && (value as any)[key]
// ? 'task col col-2 task-done'
// : 'task col col-2'

const T = styled.span<{
  active?: boolean;
}>`
  letter-spacing: -1px;
  font-size: 10px;
  line-height: 10px;
  color: ${(props) => (props.active ? '#000' : '#a1a1a1')};
  text-transform: uppercase;
`;

const W = styled.div``;

function renderTasks(tasks: any, job?: JobsAtLocation) {
  return (
    <W>
      <Row gutter={[0, 0]}>
        {tasks
          // .filter(({ key }) => job && job.tasks && job.tasks[key])
          .map(({ title, key }: { title: string; key: string }) => (
            <Col xs={24} md={12} lg={12} xxl={2} key={key}>
              <T active={job && job.tasks && job.tasks[key]}>{title}</T>
            </Col>
          ))}
      </Row>
    </W>
  );
}

const LocationJob: FunctionComponent<LocationJobProps> = ({
  onDeleteJob,
  job,
  tasks,
  filterSystem,
}) => {
  const [collapsed, setCollapsed] = useState(true);

  // if (job.state === undefined) {
  //   return null;
  // }

  const canOpen = job.taskStates && Object.keys(job.taskStates).length > 0;
  const hasTour = job.tour && job.tour.tag;
  const isLegacy = job.state === undefined;

  const legacyStatus = () => {
    if (!job.tour || !job.tour.tag) {
      return <span>[System]</span>;
    } else if ((job.tour as any).enshrined && (job.tour as any).failed) {
      return <span>[Fehlgeschlagen / Nicht gemacht]</span>;
    } else if ((job.tour as any).enshrined && !(job.tour as any).failed) {
      return <span>[Abgeschlossen]</span>;
    } else if (!(job.tour as any).enshrined) {
      return <span>[Rückpflege]</span>;
    }
  };

  if (!hasTour && filterSystem) {
    return null;
  }

  return (
    <Wrapper key={job._id}>
      <JobDetails color={colorForStatus(job)}>
        <CollapseButton
          data-collapsed={collapsed}
          collapsed={collapsed}
          onClick={() => setCollapsed(!collapsed)}
          canOpen={canOpen ?? false}
        />
        <Row gutter={[36, 24]} align={'middle'}>
          <Col xs={24} md={12} lg={12} xxl={3}>
            <Date>
              <ClockIcon />
              {renderDate(job)}
            </Date>
          </Col>
          <Col xs={24} md={12} lg={12} xxl={2}>
            {hasTour ? (
              <>
                <Date>
                  <ToursIcon />
                  {renderTour(job)}
                </Date>
              </>
            ) : null}
          </Col>
          <Col xs={24} md={12} lg={12} xxl={3}>
            {hasTour ? (
              <>
                <Date>
                  <TechIcon />
                  {renderTech(job)}
                </Date>
              </>
            ) : null}
          </Col>
          <Col xs={24} md={12} lg={12} xxl={3}>
            {hasTour ? (
              <>
                <Date>
                  <UserIcon />
                  {renderPlaner(job)}
                </Date>
              </>
            ) : null}
          </Col>
          <Col xs={24} md={12} lg={12} xxl={4}>
            {hasTour && !isLegacy ? (
              <>
                <Date>
                  <StatusIcon />
                  {renderStatus(job)}
                </Date>
              </>
            ) : null}
            {isLegacy || !hasTour ? <span>{legacyStatus()}</span> : null}
          </Col>
          <Col xs={24} lg={8} xxl={8}>
            {renderTasks(tasks, job)}
          </Col>
        </Row>
        {renderRemove(job, onDeleteJob)}
      </JobDetails>
      {
        <ErrorDisplay show={job.reason !== undefined && job.reason.length > 0}>
          Grund: {job.reason}
        </ErrorDisplay>
      }
      <Tasks>
        {!collapsed
          ? chain(job.taskStates)
              .pickBy((a, k) => k && k !== '_id')
              .map((a, task) => {
                return (
                  <TaskWrapper key={task}>
                    <Row gutter={[36, 24]} align={'middle'}>
                      <Col xs={24} md={12} lg={12} xxl={3}>
                        {tasks.find((t: any) => t.key === task)?.title}
                      </Col>
                      <Col xs={24} md={12} lg={12} xxl={2}>
                        {renderStatus(a)}
                      </Col>
                      <Col xs={24} md={12} lg={12} xxl={2}>
                        {renderReason(a)}
                      </Col>
                    </Row>
                  </TaskWrapper>
                );
              })
              .value()
          : false}
      </Tasks>
    </Wrapper>
  );
};

export default LocationJob;
